
import {Component, Vue, Prop} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import TitleManager from "@/mixins/TitleManager.vue";
import FilterBar from "@/components/ats/FilterBar.vue";
import {ROUTES} from "@/data";
import { Getter } from 'vuex-class';

@Component({
    name: "LayoutJobOffer",
    components: {
        FilterBar
    }
})
export default class Layout extends mixins(TitleManager) {


    filters: any = {
        search: "",
        jobId: "",
        clientId: "",
        source: "",
    };

    @Getter('isSupervisor') isSupervisor!: any;

    get isAddJobOffer() {
        return this.$route.name == ROUTES.ATS.JOBOFFER.ADD;
    }
    get isNewCandidate() {
        return this.$route.name == ROUTES.ATS.CV.NEWCANDIDAT;
    }

    get isReadLayout() {
        return !!this.$route.matched.find((m:any) => m.name === ROUTES.ATS.JOBOFFER.READ._ROOT);
    }

    goToNewCampain() {
        this.$router.push({name: ROUTES.ATS.JOBOFFER.ADD});
    }
}

